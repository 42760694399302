import { extend } from 'indicative/validator'
import { getValue, skippable } from 'indicative-utils'
import { validateCompanyIdent } from '~/utils/string'

let validables = {}

export default ({ app }, inject) => {
  const $cbValidator = {
    init () {
      validables = {}
    },
    addValidable (key, validator) {
      validables[key] = validator
    },
    removeValidable (key) {
      delete validables[key]
    },
    async validate () {
      const keys = Object.keys(validables)
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index]
        const validator = validables[key]

        if (!(await validator())) {
          return false
        }
      }

      return true
    }
  }

  inject('cbValidator', $cbValidator)
}

/**
 * Extend indicative validator
*/

// Simple validator which returns true everytime.
// Used as fallback validator for optional fields as
// they should pass all the time.
extend('valid', {
  validate () {
    return true
  }
})

// Checks if the strings is
// valid Finnish/Swedish company ident
extend('companyIdent', {
  validate (data, field, args, config) {
    const fieldValue = getValue(data, field)
    if (skippable(fieldValue, field, config)) {
      return true
    }

    return validateCompanyIdent(fieldValue, true)
  }
})
