//
//
//
//
//
//

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    label (title) {
      const isOptional = this.item.meta.is_optional

      return isOptional ? title : title + '*'
    }
  }
}
