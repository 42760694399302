import * as constants from '~/utils/constants'

export default function (ctx, inject) {
  const features = {}
  Object.keys(constants)
    .filter(constant => constant.startsWith('FEATURE_'))
    .forEach((constant) => {
      features[constant] = constants[constant]
    })

  inject('features', new Proxy({}, {
    get (target, name) {
      if (features[name]) {
        return features[name]
      }

      throw new Error('Feature not found: ' + name)
    }
  }))
}
