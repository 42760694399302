import get from 'lodash.get'

export default function ({ app, store }) {
  /**
   * Do not report nuxt errors which are happened during update. The app
   * will handle this situation so there is no need to report these.
   */
  app.$bugsnag.addOnError((event) => {
    // add user information to the error
    const client = store.state.auth.client
    const company = store.getters['auth/company']
    if (client) {
      event.setUser(client.id, client.attributes.email)

      if (company) {
        event.addMetadata('company', {
          id: company.id,
          displayNane: company.attributes.displayName
        })
      }
    }

    const ingored = [
      'expected expression, got \'<\'',
      'Unexpected token \'<\'',
      'ERR_REDIRECT', // nuxt uses this internally when calling redirect(), this is NOT an actual error
    ]

    const message = get(event, 'errors.0.errorMessage', '')

    const isIgnored = ingored.reduce((result, ignored) => {
      return message.includes(ignored) || result
    }, false)

    return !isIgnored
  })
}
