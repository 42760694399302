import Cookie from 'js-cookie'
import {
  STAGE_DEVELOPMENT
} from '~/utils/constants'

export const UTM_PARAMETERS = [
  'source',
  'medium',
  'campaign',
  'term',
  'content'
]

export const CAMPAIGN_PARAMETERS = [
  'source',
  'ref'
]

const options = () => {
  return {
    expires: 7,
    secure: process.env.STAGE !== STAGE_DEVELOPMENT,
    domain: process.env.COOKIE_DOMAIN
  }
}

export const setUtm = utm => Cookie.set('utm_parameters', JSON.stringify(utm), options())
export const setCampaign = campaign => Cookie.set('campaign_parameters', JSON.stringify(campaign), options())
export const getUtm = () => {
  try {
    const data = {}
    const params = JSON.parse(Cookie.get('utm_parameters'))
    Object.keys(params).forEach((key) => {
      data[`utm_${key}`] = params[key]
    })

    return data
  } catch (e) {
    return {}
  }
}
export const getCampaign = () => {
  try {
    const data = {}
    const params = JSON.parse(Cookie.get('campaign_parameters'))
    Object.keys(params).forEach((key) => {
      data[`campaign_${key}`] = params[key]
    })

    return data
  } catch (e) {
    return {}
  }
}
export const getUtmAndCampaignByAppendingToData = (data = {}) => {
  data = Object.assign(data, getUtm())
  data = Object.assign(data, getCampaign())

  return data
}
