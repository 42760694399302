import {
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_NON_RENEWING,
  SUBSCRIPTION_STATUS_TRIAL,
  SUBSCRIPTION_STATUS_PAUSED
} from '~/utils/constants'

/**
 * Returns true if the subscription can be treated "valid". That
 * means that the subscription is active and can be used.
 */
export const isSubscriptionValid = subscription => [
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_NON_RENEWING,
  SUBSCRIPTION_STATUS_TRIAL
].includes(subscription?.attributes.status)

export const isSubscriptionPaused = subscription => subscription?.attributes.status === SUBSCRIPTION_STATUS_PAUSED

export const isSubscriptionNonRenewing = subscription => subscription?.attributes.status === SUBSCRIPTION_STATUS_NON_RENEWING
