import { posthog } from 'posthog-js'
import Cookie from 'js-cookie'

export default function ({ app, store, $config }, inject) {
  const bootstrap = Cookie.get('bootstrapData')
  Cookie.remove('bootstrapData')
  posthog.init($config.POSTHOG_TOKEN, {
    api_host: 'https://eu.posthog.com',
    bootstrap,
  })
  inject('ph', posthog)
}
