import { v4 as uuid } from 'uuid'
import {
  GTM_EVENT_CONTRACT_COMPLETED,
  GTM_EVENT_ACTIVATE_SUBSCRIPTION,
  GTM_EVENT_UPGRADE_SUBSCRIPTION,
  GTM_EVENT_NEW_TRANSACTION,
  ACCOUNT_TYPE_COMPANY,
} from '~/utils/constants'

export default ({ app }, inject) => {
  const $tracker = {
    /**
     * Called when contact form submitted
     */
    submitContactForm (formId) {
      app.$gtm.push({
        event: 'submittedContactForm',
        formId
      })

      app.$ph.capture('contact_form_submitted', {
        form_id: formId
      })
    },

    /**
     * Called when contract has been finalized ("creation completed")
     */
    contractComplete ({ contractId, templateName }) {
      app.$log.debug('tracker:contractComplete', contractId, templateName)

      app.$gtm.push({
        event: GTM_EVENT_CONTRACT_COMPLETED,
        productID: contractId,
        productName: templateName
      })
    },

    /**
     * Called when user opens builder edit page
     */
    viewBuilder ({ templateName }) {
      app.$log.debug('tracker:viewBuilder', templateName)

      app.$intercom.call('trackEvent', 'view-contract-builder', {
        name: templateName
      })
    },

    /**
     * Called when user activates subscription for company.
     */
    trackActivation ({ planPriceId, planName, companyId, revenue, currency, isUpgrade, coupon }) {
      app.$gtm.push({
        event: isUpgrade ? GTM_EVENT_UPGRADE_SUBSCRIPTION : GTM_EVENT_ACTIVATE_SUBSCRIPTION,
        accountType: ACCOUNT_TYPE_COMPANY,
        planId: planPriceId // backwards compatibility
      })

      app.$ph.capture(isUpgrade ? 'subscription_upgraded' : 'subscription_created', {
        plan_id: planPriceId,
      })

      // Analytics ecommerce tracking
      // Generate transaction
      const transactionId = companyId + '-' + Date.now()
      app.$gtm.push({
        event: GTM_EVENT_NEW_TRANSACTION,
        transactionId,
        transactionTotal: revenue,
        transactionProducts: [{
          name: planName,
          category: 'Plan',
          sku: planPriceId,
          price: revenue,
          currency,
          quantity: 1
        }]
      })

      // Send GA4 compatible purchase event
      app.$gtm.push({ ecommerce: null }) // clean previous ecommerce object
      app.$gtm.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: transactionId,
          value: revenue,
          currency,
          coupon,
          items: [
            {
              item_id: planPriceId,
              item_name: planName,
              item_category: 'plan',
              coupon,
              currency,
              price: revenue,
              quantity: 1
            }
          ]
        }
      })

      // Backend sends event to Intercom about activation
    },

    /**
     * Called when subscription activation fails
     */
    trackActivationFailed ({ planPriceId, errorMessage }) {
      app.$intercom.call('trackEvent', 'activation-failed', {
        planPriceId,
        errorMessage
      })
    },

    /**
     * Called when subscription activation fails
     */
    choosedPlan ({ planId, companyName }) {
      app.$intercom.call('trackEvent', 'choosed-plan', {
        planId,
        companyName
      })
    },

    /**
     * Called when user copies content from
     * contract builder preview
     */
    copyBuilderContent (templateName) {
      app.$intercom.call('trackEvent', 'copy-builder-content', {
        templateName
      })
    },

    createContract (templateName) {
      app.$gtm.push({
        event: 'createdContract',
        templateName
      })
    },

    finalizeContract (method, templateName) {
      app.$gtm.push({
        event: 'finalizedContract',
        templateName,
        signatureMethod: method
      })
    },

    purchasedContract (templateId, templateName, price, currency) {
      // Analytics ecommerce tracking
      // Generate transaction
      const transactionId = uuid()
      app.$ph.capture('contract_purchased', {
        template_name: templateName
      })
      app.$gtm.push({
        event: GTM_EVENT_NEW_TRANSACTION,
        transactionId,
        transactionTotal: price,
        transactionProducts: [{
          name: templateName,
          category: 'Contract',
          price,
          currency,
          quantity: 1
        }]
      })

      // Send GA4 compatible purchase event
      app.$gtm.push({ ecommerce: null }) // clean previous ecommerce object
      app.$gtm.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: transactionId,
          value: price,
          currency,
          items: [
            {
              item_id: templateId,
              item_name: templateName,
              item_category: 'contract',
              currency,
              price,
              quantity: 1
            }
          ]
        }
      })
    }
  }

  inject('tracker', $tracker)
}
