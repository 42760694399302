import MockAdapter from 'axios-mock-adapter'

import {
  getApiToken,
  getActiveAccount
} from '~/utils/cookie'

import applyMocks from '~/utils/axios-mocks'
import applyAuthInterceptor from '~/utils/axios-auth-interceptor'

export default function (context) {
  const { $axios, $config, store } = context

  $axios.onRequest((config) => {
    const token = getApiToken()
    const account = getActiveAccount()
    const language = store.state.language
    const appVersion = $config.APP_VERSION || 'development'

    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`
    }

    if (account) {
      config.headers.common.ActiveAccount = account
    }

    if (language) {
      config.headers.common['Accept-Language'] = language
      config.headers.common['X-Language'] = language
    }

    if (appVersion) {
      config.headers.common['X-App-Version'] = appVersion
    }
  })

  // handle 401 cases
  applyAuthInterceptor(context)

  // register mock adapter and mocks
  const mocker = new MockAdapter($axios)
  applyMocks(mocker)
  mocker.onAny().passThrough()
}
