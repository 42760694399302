import Vue from 'vue'
import FullscreenPage from '~/components/hocs/fullscreen-page'
import CbPage from '~/components/hocs/cb-page'
import SPage from '~/components/hocs/s-page'

Vue.component('fullscreen-page', FullscreenPage)
Vue.component('cb-page', CbPage)
Vue.component('s-page', SPage)

export default ({ app }, inject) => {
  const $page = {
    startLoading: () => {
      app.store.dispatch('startLoading')
    },
    stopLoading: () => {
      app.store.dispatch('stopLoading')
    }
  }

  inject('page', $page)
}
