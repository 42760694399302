//
//
//
//
//
//
//
//
//

export default {
  computed: {
    isLoading () {
      return this.$store.state.loadingCount > 0
    }
  }
}
