import {
  UTM_PARAMETERS,
  CAMPAIGN_PARAMETERS,
  setUtm,
  setCampaign
} from '~/utils/utm'

/**
 * Stores utm and campaign query parameters to cookie
 * to be referenced in registration
 */
export default function ({ query }) {
  const parameters = Object.keys(query)

  const hasUtm = !!parameters.find(p => p.indexOf('utm_') === 0)
  if (hasUtm) {
    const utm = {}
    UTM_PARAMETERS.forEach((parameter) => {
      utm[parameter] = query['utm_' + parameter] || 'none'
    })

    setUtm(utm)
  }

  const hasCampaign = !!parameters.find(p => p.indexOf('campaign_') === 0)
  if (hasCampaign) {
    const campaign = {}
    CAMPAIGN_PARAMETERS.forEach((parameter) => {
      campaign[parameter] = query['campaign_' + parameter] || 'none'
    })

    setCampaign(campaign)
  }
}
