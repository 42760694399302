import { removeActiveAccount, removeApiToken } from '~/utils/cookie'
export default function axiosAuthInterceptor (ctx) {
  const { $axios, redirect } = ctx

  $axios.onError((err) => {
    // To prevent race-condiditions when intentionally trying to fetch
    // user profile without valid credentials (to check if they are valid)
    // we do not redirect on GET /me profile fetches.
    if (err?.request?.responseURL) {
      const url = new URL(err?.request?.responseURL)
      if (['/api/me', '/me'].includes(url.pathname)) {
        return
      }
    }

    if (err?.response?.status === 401) {
      removeApiToken()
      removeActiveAccount()

      if (location.pathname !== '/login') {
        redirect(`/login?return=${encodeURIComponent(location.href)}`)
      }
    }
  })
}
