import * as log from 'loglevel'
import {
  STAGE_PRODUCTION
} from '~/utils/constants'

// Expose logger to window so it can be enabled also in production
if (!window.log) {
  window.log = log
}

// Disable all logging in production by default
if (process.env.STAGE === STAGE_PRODUCTION) {
  log.disableAll()
} else {
  log.enableAll()
}

// Make logger available for pages and components as $log
export default ({ app }, inject) => {
  inject('log', log)
}
