import { PARTNER_TYPE_FINAGO } from '~/utils/constants'
import { appendQuery } from '~/utils/url'
const fallbackUrls = {
  privacy_policy: 'https://docue.com/en/privacy',
  terms: 'https://docue.com/en/terms',
}

const partnerUrls = {
  [PARTNER_TYPE_FINAGO]: {
    privacyPolicy: 'https://finago.com/fi/tietosuojaseloste/',
    terms: 'https://finago.com/fi/finago-sopimuskone-kayttoehdot/'
  }
}

export default function (ctx, inject) {
  // uses api v1 camelcase format for keys
  const linkGetter = ({ key, partnerKey = key, partner }) => () => {
    const account = ctx.store.getters['auth/account']
    if (account?.partner && account.partner[`${partnerKey}Url`]) {
      return account.partner[`${partnerKey}Url`]
    } else if (partner && partnerUrls?.[partner]?.[partnerKey]) {
      return partnerUrls[partner][partnerKey]
    }

    return ctx.store.getters['auth/countryConfiguration']?.links[key] || fallbackUrls[key]
  }

  inject('links', {
    privacyPolicy: partner => linkGetter({ key: 'privacy_policy', partnerKey: 'privacyPolicy', partner })(),
    terms: partner => linkGetter({ key: 'terms', partner })(),
    pricing: partner => linkGetter({ key: 'pricing', partner })(),
    supportPortal: partner => linkGetter({ key: 'support_portal', partnerKey: 'supportPortal', partner })(),
    item: {
      builder (itemId) {
        return `/builder/edit?itemId=${itemId}`
      },
      archive (itemId) {
        return `/documents?accountItemId=${itemId}`
      }
    },
    document: {
      archive (documentId) {
        return `/2/documents/d/${documentId}`
      },
    },
    contract: {
      builder (contractId) {
        return `/builder/edit?cId=${contractId}`
      },
    },
    templates: {
      root () { return '/templates' },
      recent () { return '/templates/recent' },
      favourites () { return '/templates/favourites' },
      own () { return '/templates/own' },
      all () { return '/templates/all' },
      single (id) { return `/templates/${id}` },
      category (categoryId) {
        return appendQuery('/templates/category', { categoryId })
      },
      collection (collectionId) {
        return appendQuery('/templates/collection', { collectionId })
      },
      search () { return '/templates/search' },
    }
  })
}
