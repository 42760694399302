import { loadStripe } from '@stripe/stripe-js/pure'

export default function ({ store, $config }, inject) {
  inject('stripe', {
    async init () {
      const countryConfig = store.getters['auth/countryConfiguration']
      if (!countryConfig) {
        await store.dispatch('auth/fetchCountryConfiguration')
      }

      const key = store.getters['auth/countryConfiguration']?.stripe?.publishable_key
      if (!key) {
        throw new Error('No Stripe key for country: ' + store.getters['auth/account'].country)
      }

      return await loadStripe(key)
    }
  })
}
