//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { v4 as uuid } from 'uuid'

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    content: {
      type: String,
      required: false,
      default: ''
    },
    longContent: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      id: uuid(),
      expanded: false
    }
  },
  computed: {
    hasLongContent () {
      return !!this.longContent
    },
    wider () {
      return this.content.length > 300
    },
    hasLegacyContent () {
      // Legacy content does not have p tags.
      // If we have legacy content, we assume that new lines works
      // correctly so we enable white-space: pre-line;
      return !this.content.includes('<p>')
    },
    customClass () {
      const classes = ['cb-tooltip-popover']

      if (this.wider) {
        classes.push('wider')
      }

      if (this.hasLegacyContent) {
        classes.push('legacy-content')
      }

      return classes.join(' ')
    }
  },
  methods: {
    expand () {
      this.$root.$emit('bv::hide::popover', this.id)
      this.$store.commit('builder/SET_VISIBLE_TOOLTIP', {
        title: this.title,
        content: this.longContent
      })
    }
  }
}
