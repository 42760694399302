
/**
 * Returns client's full name. Returns empty string
 * if the "name" attribute fallbacks to client's email
 * address.
 *
 * @param {*} client
 */
export const getOnlyName = (client) => {
  if (client.attributes.name !== client.attributes.email) { return client.attributes.name || '' }

  return ''
}
