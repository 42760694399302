import { clearState } from '~/utils/state'

export const state = () => ({
  partners: []
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_PARTNERS (state, partners) {
    state.partners = partners
  }
}

export const actions = {
  async fetchPartners ({ commit }) {
    const response = await this.$axios.$get('/partners')
    await commit('SET_PARTNERS', response.data)
  }
}

export const getters = {
}
