//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    headerColor: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.loadingCount > 0
    }
  },
  methods: {
    hasSidebar () {
      return !!this.$slots.sidebar
    },
    hasTopHeader () {
      return !!this.$slots['top-header']
    }
  }
}
