import { clearState } from '~/utils/state'
import { SUBSCRIBE_MODAL2_STAGE_PLANS, SUBSCRIBE_MODAL2_STAGES, PERIOD_YEAR, PERIOD_MONTH, FEATURE_ID_SIGNATURE } from '~/utils/constants'
import { getFeatureConfigs } from '~/utils/subscribe'

export const state = () => {
  return {
    stage: SUBSCRIBE_MODAL2_STAGE_PLANS,

    priceList: null,
    priceListIsNotAvailable: false,

    selectedItem: null,
    estimate: null,
    estimateLoading: false,
    coupon: null,
    period: null,
    paymentMethod: null,

    billingCountry: null,
    billingCompanyName: null,
    billingCompanyIdent: null,
    billingFirstName: null,
    billingLastName: null,
    billingAddress: null,
    billingPostCode: null,
    billingCity: null,
    billingEmail: null,
    billingVatNumber: null,

    billingEInvoiceAccount: null,
    billingEInvoiceOperator: null,

    featureAddonPriceIds: {
      [FEATURE_ID_SIGNATURE]: null,
    },
  }
}

export const mutations = {
  CLEAR: clearState(state()),
  UPDATE_FEATURE_ADDON_PRICE_ID (state, { featureId, priceId }) {
    state.featureAddonPriceIds[featureId] = priceId
  },
  SET_STAGE (state, stage) {
    state.stage = stage
  },
  SET_PRICE_LIST (state, list) {
    state.priceList = list
  },
  SET_PRICE_LIST_IS_NOT_AVAILABLE (state, notAvailable) {
    state.priceListIsNotAvailable = notAvailable
  },
  SET_SELECTED_ITEM (state, item) {
    state.selectedItem = item
  },
  SET_ESTIMATE (state, estimate) {
    state.estimate = estimate
    state.estimateLoading = false
  },
  SET_ESTIMATE_LOADING (state) {
    state.estimateLoading = true
  },
  SET_COUPON (state, coupon) {
    state.coupon = coupon
  },
  SET_PERIOD (state, period) {
    state.period = period
  },
  SET_PAYMENT_METHOD (state, method) {
    state.paymentMethod = method
  },
  SET_BILLING_COUNTRY (state, country) {
    state.billingCountry = country
  },
  SET_BILLING_COMPANY_NAME (state, companyName) {
    state.billingCompanyName = companyName
  },
  SET_BILLING_COMPANY_IDENT (state, companyIdent) {
    state.billingCompanyIdent = companyIdent
  },
  SET_BILLING_FIRST_NAME (state, firstName) {
    state.billingFirstName = firstName
  },
  SET_BILLING_LAST_NAME (state, lastName) {
    state.billingLastName = lastName
  },
  SET_BILLING_ADDRESS (state, address) {
    state.billingAddress = address
  },
  SET_BILLING_POST_CODE (state, postCode) {
    state.billingPostCode = postCode
  },
  SET_BILLING_CITY (state, city) {
    state.billingCity = city
  },
  SET_BILLING_EMAIL (state, email) {
    state.billingEmail = email
  },
  SET_BILLING_E_INVOICE_ACCOUNT (state, account) {
    state.billingEInvoiceAccount = account
  },
  SET_BILLING_E_INVOICE_OPERATOR (state, operator) {
    state.billingEInvoiceOperator = operator
  },
  SET_BILLING_VAT_NUMBER (state, vat) {
    state.billingVatNumber = vat
  },
}

export const getters = {
  /**
   * Get the available pricing periods from the items. A period
   * should be included only if it is available in every plan.
   */
  periods (state, getters, rootState, rootGetters) {
    if (!state.priceList) {
      return []
    }

    return getPeriodsForPriceList(state.priceList, rootGetters, rootState)
  },

  selectedItemPrice (state) {
    return state.selectedItem?.prices.find(p => p.period_unit === state.period)
  },

  featureConfigs (state) {
    return getFeatureConfigs(state.priceList, state.period)
  },

}

export const actions = {
  async fetchEstimate ({ rootGetters, getters, commit, state }) {
    await commit('SET_ESTIMATE', null)

    if (!getters.selectedItemPrice) {
      return
    }

    await commit('SET_ESTIMATE_LOADING')

    const company = await rootGetters['auth/company']
    const data = {
      plan: getters.selectedItemPrice.id,
      coupon: state.coupon,
      addons: Object.values(state.featureAddonPriceIds).map((addonPriceId) => {
        if (!addonPriceId) {
          return null
        }

        return {
          id: addonPriceId,
          quantity: 1,
        }
      }).filter(addon => !!addon)
    }

    const estimate = await this.$axios.$post(`/me/companies/${company.id}/subscription/estimate`, data)
    await commit('SET_ESTIMATE', estimate)

    return estimate
  },
  async setSelectedItem ({ commit, dispatch }, item) {
    await commit('SET_SELECTED_ITEM', item)
    dispatch('fetchEstimate')
  },
  setPeriod ({ dispatch, commit, state, getters }, period) {
    commit('SET_PERIOD', period)
    dispatch('fetchEstimate')
  },
  updateFeatureAddonPriceId ({ commit, dispatch }, ctx) {
    commit('UPDATE_FEATURE_ADDON_PRICE_ID', ctx)
    dispatch('fetchEstimate')
  },
  previousStage ({ commit, state }) {
    commit('SET_STAGE', SUBSCRIBE_MODAL2_STAGES[SUBSCRIBE_MODAL2_STAGES.indexOf(state.stage) - 1] || SUBSCRIBE_MODAL2_STAGE_PLANS)
  },
  nextStage ({ commit, state }) {
    commit('SET_STAGE', SUBSCRIBE_MODAL2_STAGES[SUBSCRIBE_MODAL2_STAGES.indexOf(state.stage) + 1] || SUBSCRIBE_MODAL2_STAGE_PLANS)
  },
  setStage ({ commit }, stage) {
    commit('SET_STAGE', stage)
  },
  async fetchPriceList ({ commit, state, rootGetters, rootState }, { name = 'current' }) {
    if (state.priceList) {
      return
    }

    try {
      const list = await this.$axios.$get(`/v2/price-lists/${name}?include=addons.prices`)

      // Set initial period. It should be the first available period.
      await commit('SET_PERIOD', getPeriodsForPriceList(list, rootGetters, rootState)[0])

      await commit('SET_PRICE_LIST', list)
    } catch (error) {
      if (error.response?.status === 404) {
        await commit('SET_PRICE_LIST_IS_NOT_AVAILABLE', true)
        return
      }

      throw error
    }
  },
}

function getPeriodsForPriceList (priceList, rootGetters, rootState) {
  // If workspace has active subscription, we allow upgrading only
  // to the same billing period plan.
  if (rootGetters['auth/hasValidSubscription'] && rootState.auth.subscription) {
    return [rootState.auth.subscription.attributes.billingPeriod]
  }

  const hasAnnualPlan = priceList.items.every(item => !!item.prices.find(p => p.period_unit === PERIOD_YEAR && p.period === 1))
  const hasMonthlyPlan = priceList.items.every(item => !!item.prices.find(p => p.period_unit === PERIOD_MONTH && p.period === 1))

  const periods = []

  if (hasAnnualPlan) {
    periods.push(PERIOD_YEAR)
  }

  if (hasMonthlyPlan) {
    periods.push(PERIOD_MONTH)
  }

  return periods
}
