import axios from 'axios'

// Create cache validation timestamp just once
// and use that for all requests
const cv = Math.floor(Date.now() / 1000)

export default ({ $config }, inject) => {
  const token = $config.STORYBLOK_TOKEN

  const configsByCountry = {}

  inject('storyblok', {
    async getAppConfig (country) {
      const relations = [
        'activation_nagger_logos',
        'subscribe_modal_quotes',
        'subscribe_modal_logo_cloud',
      ]

      if (!configsByCountry[country]) {
        try {
          configsByCountry[country] = await axios.get(`https://api.storyblok.com/v2/cdn/stories/data/app-config-${country.toLowerCase()}`, {
            params: {
              token,
              cv,
              resolve_relations: relations.join(',')
            }
          }).then(r => resolveRelations(r.data, relations).story)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          return null
        }
      }

      return configsByCountry[country]
    },
  })
}

function resolveRelations (config, relations) {
  relations.forEach((relation) => {
    const target = config.story.content[relation]

    if (Array.isArray(target)) {
      config.story.content[relation] = target.map((uuid) => {
        return config.rels.find(rel => rel.uuid === uuid) || uuid
      })
    }
  })

  return config
}
