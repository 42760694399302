import {
  setApiToken,
  setActiveAccount
} from '~/utils/cookie'
import { clearState } from '~/utils/state'

export const state = () => ({
  event: null
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_EVENT (state, event) {
    state.event = event
  }
}

export const actions = {
  async fetchEvent ({ commit }, { eventId, acceptToken }) {
    const response = await this.$axios.$get(`/salesEvents/getOwnEvent/${eventId}?acceptToken=${acceptToken}`)
    await commit('SET_EVENT', response.data)
  },
  async activate ({ dispatch }, { eventId, acceptToken, password }) {
    const response = await this.$axios.$post(`/salesEvents/${eventId}/accept`, {
      acceptToken,
      password,
      password_confirmation: password
    })

    setApiToken(response.token)
    setActiveAccount(response.companyId)
    await dispatch('clearAll', null, { root: true })
    this.$router.push('/')
  }
}
