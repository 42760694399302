import sortBy from 'lodash.sortby'

export default ({ store }, inject) => {
  let countries, locale, clientLang
  const initCountries = () => {
    const client = store.state.auth.client
    // check to prevent unnecessary re-requires
    if (countries && locale && clientLang === client?.attributes?.language) {
      return {
        countries,
        targetLang: clientLang
      }
    }
    clientLang = client.attributes.language
    countries = require('i18n-iso-countries')
    locale = require(`i18n-iso-countries/langs/${clientLang}.json`)
    countries.registerLocale(locale)
    return {
      countries,
      targetLang: clientLang
    }
  }

  const getCountryNameByCode = (code) => {
    const { countries, targetLang } = initCountries()
    return countries.getName(code, targetLang)
  }

  const getAllCountryOptions = () => {
    const { countries, targetLang } = initCountries()
    return sortBy(
      Object.keys(countries.getAlpha2Codes())
        .map(value => ({
          text: countries.getName(value, targetLang),
          value
        })),
      value => value.text
    )
  }

  inject('country', {
    getAllCountryOptions,
    getCountryNameByCode
  })
}
