//
//
//

export default {
  head: {
    bodyAttrs: {
      class: 'payment'
    }
  }
}
