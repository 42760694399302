import { ALL_LANGUAGES, ALL_COUNTRIES, LANGUAGE_EN } from '~/utils/constants'

export default ({ store }, inject) => {
  const locale = () => {
    const authUserLocale = store.getters['auth/locale']

    /**
     * if user is not authenticated value will be something like 'fi-undefined',
     * check it and fallback to browser default or en
     */
    const parts = authUserLocale.split('-').filter(x => [...ALL_LANGUAGES, ...ALL_COUNTRIES].includes(x))
    if (parts.length === 2) {
      return parts.join('-')
    }

    return window?.navigator?.languages?.[0] ?? LANGUAGE_EN
  }

  /**
   * @param {Date | string} date value to be formatted
   * @param {Intl.DateTimeFormatOptions} opts options for formatting date value
   * @returns formatted date string
   */
  const formatDate = (date, opts) => {
    return new Intl.DateTimeFormat(locale(), opts).format(date instanceof Date ? date : new Date(date))
  }

  inject('intl', { formatDate })
}
